// @flow
import * as React from 'react'
import Client from 'mz-sdk/client'
import moment from 'moment'
import { FormattedMoment } from 'mz-intl'
import { useDataLoader } from 'mz-ui-kit/logic/DataLoader/useDataLoader'
import { useStyletron } from 'baseui'
import { StyledLink } from 'baseui/link'
import { ProgressBar } from 'baseui/progress-bar'
import { Skeleton } from 'baseui/skeleton'
import { Pagination } from 'baseui/pagination'
import { Notification, KIND as NOTIFICATION_KIND } from 'baseui/notification'
import {
  StyledTable,
  StyledHead,
  StyledHeadCell,
  StyledBody,
  StyledRow,
  StyledCell,
} from '../Table'

type DataType = {
  pages: number,
  results: Array<any>,
}

function createLoadingResults(size) {
  return [...Array(size).keys()].map(() => [
    <Skeleton key="2" width="100px" height="20px" animation />,
    <Skeleton
      key="6"
      width={`${80 + randomInteger(-30, 30)}px`}
      height="20px"
      animation
    />,
    <Skeleton key="2" width="100px" height="20px" animation />,
    <Skeleton key="2" width="100px" height="20px" animation />,
  ])
}

function randomInteger(min, max) {
  return Math.random() * (max - min) + min
}

function createRealResult(payment) {
  return [
    `${payment.month} / ${payment.year}`,
    payment.amount.display,
    !!payment.paid_on && (
      <FormattedMoment key="1" value={moment(payment.paid_on)} format="L" />
    ),
    payment.status,
  ]
}

export default () => {
  const [css] = useStyletron()
  const [currPage, setCurrPage] = React.useState(1)
  const [{ data, loading, error }] = useDataLoader<DataType>(async () => {
    const result = await Client.get('/partners-stats/payments/', {
      query: { page: currPage, page_size: 20 },
    })
    const pages = Math.ceil(result.count / 20)

    return {
      pages,
      results: result.results.map(createRealResult),
    }
  }, [currPage])

  if (error) {
    return (
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        })}
      >
        <Notification kind={NOTIFICATION_KIND.negative}>
          We are sorry, but we can&apos;t load your payments at this time.
          Please try again later or contact our{' '}
          <StyledLink href="mailto:support@mozio.com">support</StyledLink> for
          further assistance
        </Notification>
      </div>
    )
  }

  function handleChangePage({ nextPage }) {
    setCurrPage(nextPage)
  }

  const actualResults = data ? data.results : createLoadingResults(20)

  return (
    <div>
      <StyledTable>
        <StyledHead>
          <StyledHeadCell $maxWidth="100px">Period</StyledHeadCell>
          <StyledHeadCell $maxWidth="150px">Amount</StyledHeadCell>
          <StyledHeadCell $maxWidth="100px">Paid On</StyledHeadCell>
          <StyledHeadCell>Status</StyledHeadCell>
        </StyledHead>
        <StyledBody>
          {actualResults.map((row, index) => (
            <StyledRow key={index}>
              <StyledCell $maxWidth="100px">{row[0]}</StyledCell>
              <StyledCell $maxWidth="150px">{row[1]}</StyledCell>
              <StyledCell $maxWidth="100px">{row[2]}</StyledCell>
              <StyledCell>{row[3]}</StyledCell>
            </StyledRow>
          ))}
        </StyledBody>
      </StyledTable>
      {!data && <Skeleton width="400px" height="50px" animation />}
      {data && (
        <ProgressBar
          infinite
          key={currPage}
          overrides={{
            Root: {
              style: {
                visibility: loading ? 'visible' : 'hidden',
              },
            },
          }}
        />
      )}
      {data && (
        <Pagination
          numPages={data.pages}
          currentPage={currPage}
          onPageChange={handleChangePage}
        />
      )}
    </div>
  )
}
