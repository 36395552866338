// @flow
import { withStyle } from 'baseui'
import {
  StyledTable as RawStyledTable,
  StyledHead as RawStyledHead,
  StyledHeadCell as RawStyledHeadCell,
  StyledBody as RawStyledBody,
  StyledCell as RawStyledCell,
} from 'baseui/table'

export * from 'baseui/table'

export const StyledHead = withStyle<typeof RawStyledHead, {}>(
  RawStyledHead,
  ({ $theme }) => ({
    width: '100%',
    minWidth: '1000px',
    borderBottomColor: $theme.colors.primary,
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
    boxShadow: 'none',
  })
)

export const StyledBody = withStyle(RawStyledBody, {
  width: '100%',
  minWidth: '1000px',
})

export const StyledHeadCell = withStyle<
  typeof RawStyledHeadCell,
  { $maxWidth?: string }
>(RawStyledHeadCell, ({ $maxWidth }) => ({
  alignItems: 'center',
  borderLeftWidth: 0,
  borderRightWidth: 0,
  maxWidth: $maxWidth || 'initial',
}))

export const StyledTable = withStyle<
  typeof RawStyledTable,
  { $isLoading?: boolean }
>(RawStyledTable, ({ $isLoading }) => ({
  padding: '0 10px 0 10px',
  marginBottom: '20px',
  opacity: $isLoading ? 0.5 : 1,
  pointerEvents: $isLoading ? 'none' : 'initial',
}))

export const StyledCell = withStyle<
  typeof RawStyledCell,
  { $maxWidth?: string }
>(RawStyledCell, ({ $maxWidth }) => ({
  maxWidth: $maxWidth || 'initial',
}))
