// @flow
import * as React from 'react'
import moment from 'moment'
import { FormattedMoment } from 'mz-intl'
import { Redirect } from 'react-router-dom'
import APIError from 'mz-sdk/client/APIError'
import Client from 'mz-sdk/client'
import { useDataLoader } from 'mz-ui-kit/logic/DataLoader/useDataLoader'
import { Skeleton } from 'baseui/skeleton'
import { Notification, KIND } from 'baseui/notification'
import StatisticCard from '../StatisticCard'
import { styled } from 'baseui'
import { H1 } from 'baseui/typography'

type DataType = {
  invoicePartner?: boolean,
  partnerName: React.Node,
  lastPaymentValue: React.Node,
  lastPaymentCaption: React.Node,
  earningThisMonth: React.Node,
  totalDebt: React.Node,
}

function getLastPaymentCaption(payment: ?Object) {
  if (!payment) {
    return 'No payments initiated yet'
  }
  if (payment.paid_on) {
    const paidMoment = moment(payment.paid_on)
    return (
      <>
        Paid on <FormattedMoment value={paidMoment} format="L" />
      </>
    )
  }
  return (
    <>
      Payment is in processing, status <b>{payment.status}</b>
    </>
  )
}

function getPropperErrorMessage(error: ?APIError) {
  if (error?.status === 403) {
    return <Redirect to="/request-permissions" />
  }
  return 'Sorry, some error occurred, try again later'
}

const Statistics = styled('div', {
  display: 'flex',
  marginBottom: '10px',
})

const StyledStatisticCard = styled(StatisticCard, {
  width: '100%',
  marginRight: '10px',
  ':last-child': {
    marginRight: '0',
  },
})

export default () => {
  const [{ data, loading, error }] = useDataLoader<DataType>(async () => {
    const result = await Client.get('/partners-stats/summary/')
    return {
      invoicePartner: result.partner.invoice,
      partnerName: result.partner.name,
      lastPaymentValue: result.last_payment?.amount.display || '$0.00',
      lastPaymentCaption: getLastPaymentCaption(result.last_payment),
      earningThisMonth: result.current_month_volume.display,
      totalDebt: result.total_debt.display,
    }
  }, [])

  if (error) {
    return (
      <Notification kind={KIND.negative}>
        {getPropperErrorMessage(error)}
      </Notification>
    )
  }

  const actualData =
    !loading && data
      ? data
      : {
          partnerName: <Skeleton height="52px" width="200px" animation />,
          lastPaymentValue: <Skeleton height="44px" width="200px" animation />,
          lastPaymentCaption: (
            <Skeleton height="20px" width="130px" animation />
          ),
          earningThisMonth: <Skeleton height="50px" width="200px" animation />,
          totalDebt: <Skeleton height="44px" width="200px" animation />,
        }

  return (
    <>
      <H1>{actualData.partnerName}</H1>
      {!data?.invoicePartner && (
        <Statistics>
          <StyledStatisticCard
            header="Last Payment"
            value={actualData.lastPaymentValue}
            caption={actualData.lastPaymentCaption}
            seeAllPath="/payments"
          />
          <StyledStatisticCard
            header="Earning This Month"
            value={actualData.earningThisMonth}
          />
          <StyledStatisticCard
            header="Total Debt"
            value={actualData.totalDebt}
            caption="Without current month"
          />
        </Statistics>
      )}
    </>
  )
}
