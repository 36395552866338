// @flow
import * as React from 'react'
import {
  HeaderNavigation,
  ALIGN,
  StyledNavigationList,
  StyledNavigationItem,
} from 'baseui/header-navigation'
import doLogout from 'mz-sdk/services/auth/logout'
import MozioLogo from '../MozioLogo'
import { Button, KIND } from 'baseui/button'
import { Block } from 'baseui/block'
import { useHistory } from 'react-router-dom'

function Container(props) {
  return (
    <Block
      display="flex"
      justifyContent="center"
      minHeight="calc(100vh - 73px)"
      backgroundColor="backgroundSecondary"
      paddingLeft="scale800"
      paddingRight="scale800"
    >
      <Block
        width="100%"
        maxWidth="80em"
        marginTop="scale800"
        marginBottom="scale800"
        {...props}
      />
    </Block>
  )
}

export default ({ children }: { children: React.Node }) => {
  const history = useHistory()

  function handleClickLogout() {
    doLogout()
    history.push('login')
  }

  return (
    <>
      <HeaderNavigation
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              paddingRight: $theme.sizing.scale800,
            }),
          },
        }}
      >
        <StyledNavigationList $align={ALIGN.left}>
          <StyledNavigationItem>
            <MozioLogo />
          </StyledNavigationItem>
        </StyledNavigationList>
        <StyledNavigationList $align={ALIGN.center} />
        <StyledNavigationList $align={ALIGN.right}>
          <StyledNavigationItem>
            <Button onClick={handleClickLogout} kind={KIND.minimal}>
              Logout
            </Button>
          </StyledNavigationItem>
        </StyledNavigationList>
      </HeaderNavigation>
      <Container>{children}</Container>
    </>
  )
}
