import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import config from './config'
import setConfig from 'mz-sdk/config'
import { setOsano } from './utils/osano'

setConfig(config.MOZIO_SDK)
setOsano()

ReactDOM.render(<App />, document.getElementById('root'))
