// @flow
import * as React from 'react'
import moment from 'moment'
import { FormControl } from 'baseui/form-control'
import { DatePicker } from 'baseui/datepicker'
import { SIZE as INPUT_SIZE } from 'baseui/input'
import { COLUMNS } from './constants'
import type { ColumnT } from './types'
import FilterShell from './filter-shell'

type OptionsT = {|
  maxWidth?: number,
  minWidth?: number,
  title: string,
  caption?: string,
|}

type FilterParametersT = {|
  description: string,
  range: Array<Date>,
|}

type DateColumnT = ColumnT<FilterParametersT>

type DateFilterProps = {
  close: () => void,
  title: string,
  caption?: string,
  setFilter: (FilterParametersT) => void,
  filterParams?: FilterParametersT,
}

export function DateFilter(props: DateFilterProps) {
  const [query, setValue] = React.useState<Array<Date>>(
    props.filterParams?.range || []
  )

  return (
    <FilterShell
      onApply={() => {
        if (!query || query.length < 1) return
        let description = moment(query[0]).format('L')
        if (query.length > 1) {
          description += ` – ${moment(query[1]).format('L')}`
        }

        props.setFilter({ description, range: query })
        props.close()
      }}
    >
      <FormControl label={props.title} caption={props.caption}>
        <DatePicker
          size={INPUT_SIZE.compact}
          value={query}
          onChange={({ date }) =>
            date && setValue(Array.isArray(date) ? date : [date])
          }
          clearable
          quickSelect
          range
        />
      </FormControl>
    </FilterShell>
  )
}

function DateColumn(options: OptionsT): DateColumnT {
  return {
    kind: COLUMNS.DATETIME,
    maxWidth: options.maxWidth,
    minWidth: options.minWidth,
    renderFilter: (props) => (
      <DateFilter {...props} title={options.title} caption={options.caption} />
    ),
    title: options.title,
  }
}

export default DateColumn
