// @flow

import * as React from 'react'

import { useStyletron } from 'baseui'
import { Button, SIZE as BUTTON_SIZE } from 'baseui/button'

type PropsT = {
  children: React.Node,
  onApply: () => void,
}

function FilterShell(props: PropsT) {
  const [css, theme] = useStyletron()

  return (
    <form
      className={css({
        backgroundColor: theme.colors.backgroundPrimary,
        paddingTop: theme.sizing.scale600,
        paddingRight: theme.sizing.scale600,
        paddingBottom: theme.sizing.scale600,
        paddingLeft: theme.sizing.scale600,
        width: '320px',
      })}
      onSubmit={(event) => {
        event.preventDefault()
        props.onApply()
      }}
    >
      {props.children}
      <div
        className={css({
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: theme.sizing.scale600,
        })}
      >
        <Button size={BUTTON_SIZE.compact} type="submit">
          Apply
        </Button>
      </div>
    </form>
  )
}

export default FilterShell
