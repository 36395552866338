/* eslint-disable no-empty */
/* eslint-disable no-fallthrough */
// @flow
import * as React from 'react'
import { useStyletron } from 'baseui'
import { Tag, VARIANT as TAG_VARIANT } from 'baseui/tag'
import { Popover } from 'baseui/popover'
import FilterMenu from './filter-menu'
import type { ColumnT } from './types'

function FilterTag(props) {
  const [, theme] = useStyletron()
  const [isOpen, setIsOpen] = React.useState(false)
  const columnIndex = props.columns.findIndex((c) => c.title === props.title)
  const column = props.columns[columnIndex]
  if (!column) {
    return null
  }

  const Filter = column.renderFilter

  return (
    <Popover
      focusLock
      returnFocus
      key={props.title}
      isOpen={isOpen}
      onClickOutside={() => setIsOpen(false)}
      content={() => (
        <Filter
          close={() => setIsOpen(false)}
          filterParams={(props.filter: any)} // FIXME: current flow version complains
          setFilter={(filterParams) =>
            props.onFilterAdd(props.title, filterParams)
          }
        />
      )}
    >
      <div>
        <Tag
          onClick={() => setIsOpen(!isOpen)}
          onActionClick={() => props.onFilterRemove(props.title)}
          variant={TAG_VARIANT.outlined}
          overrides={{
            Text: {
              style: {
                maxWidth: '300px',
              },
            },
            Root: {
              style: {
                borderTopLeftRadius: '36px',
                borderTopRightRadius: '36px',
                borderBottomLeftRadius: '36px',
                borderBottomRightRadius: '36px',
                height: '36px',
                marginTop: theme.sizing.scale100,
                marginBottom: theme.sizing.scale100,
              },
            },
            Action: {
              style: {
                borderTopRightRadius: '36px',
                borderBottomRightRadius: '36px',
                height: '22px',
              },
            },
          }}
        >
          <b>{props.title}</b>: {props.filter.description}
        </Tag>
      </div>
    </Popover>
  )
}

export function TableFilter({
  value,
  onChange,
  columns,
  placeholder,
  isLoading,
  totalCount,
}: {
  value: ?Map<string, any>,
  columns: Array<ColumnT<any>>,
  onChange: (Map<string, any>) => any,
  placeholder?: string,
  isLoading?: boolean,
  totalCount?: number,
}) {
  const [css, theme] = useStyletron()
  const filters = value || new Map()

  function handleSetFilter(title, filterParams) {
    const newFilter = new Map(filters)
    newFilter.set(title, filterParams)
    onChange(newFilter)
  }

  function handleDeleteFilter(title) {
    const newFilter = new Map(filters)
    newFilter.delete(title)
    onChange(newFilter)
  }

  return (
    <div
      className={css({
        display: 'flex',
        width: '100%',
        backgroundColor: theme.colors.inputFill,
      })}
    >
      <div
        className={css({
          flex: '1 1 0%',
          flexWrap: 'wrap',
          display: 'flex',
          alignItems: 'center',
          marginLeft: theme.sizing.scale400,
        })}
      >
        {!filters.size && (
          <div
            className={css({
              color: theme.colors.inputPlaceholder,
              paddingLeft: theme.sizing.scale600,
            })}
          >
            {placeholder}
          </div>
        )}
        {Array.from(filters).map(([title, filter]) => (
          <FilterTag
            key={title}
            columns={columns}
            filter={filter}
            onFilterAdd={handleSetFilter}
            onFilterRemove={handleDeleteFilter}
            title={title}
          />
        ))}
      </div>
      <div>
        {!isLoading && (
          <span
            className={css({
              marginRight: '10px',
              marginLeft: '10px',
              color: theme.colors.inputPlaceholder,
            })}
          >
            {!filters.size ? 'Total' : 'Found'} {totalCount || 0} items
          </span>
        )}
        <FilterMenu
          columns={columns}
          filters={filters}
          onSetFilter={handleSetFilter}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}

export default TableFilter
export { default as StringColumn } from './column-string'
export { default as DateColumn } from './column-date'
