// @flow
import { createTheme } from 'baseui'
import tinycolor from 'tinycolor2'

export default createTheme({
  primary: '#0D84EB',
  primary700: tinycolor('#0D84EB').lighten(20).toString(),
  primary600: tinycolor('#0D84EB').lighten(33).toString(),
  primary500: tinycolor('#0D84EB').lighten(46).toString(),
  primary400: tinycolor('#0D84EB').lighten(69).toString(),
  primary300: tinycolor('#0D84EB').lighten(80).toString(),
  primary200: tinycolor('#0D84EB').lighten(89).toString(),
  primary100: tinycolor('#0D84EB').lighten(93).toString(),
  primary50: tinycolor('#0D84EB').lighten(96).toString(),

  accent: '#FCD012',
  accent700: tinycolor('#FCD012').lighten(20).toString(),
  accent600: tinycolor('#FCD012').lighten(33).toString(),
  accent500: tinycolor('#FCD012').lighten(46).toString(),
  accent400: tinycolor('#FCD012').lighten(69).toString(),
  accent300: tinycolor('#FCD012').lighten(80).toString(),
  accent200: tinycolor('#FCD012').lighten(89).toString(),
  accent100: tinycolor('#FCD012').lighten(93).toString(),
  accent50: tinycolor('#FCD012').lighten(96).toString(),
})
