// @flow

export const COLUMNS = Object.freeze({
  ANCHOR: 'ANCHOR',
  BOOLEAN: 'BOOLEAN',
  CATEGORICAL: 'CATEGORICAL',
  CUSTOM: 'CUSTOM',
  DATETIME: 'DATETIME',
  NUMERICAL: 'NUMERICAL',
  STRING: 'STRING',
})
