// @flow
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { styled } from 'baseui'
import doLogout from 'mz-sdk/services/auth/logout'
import { Button } from 'baseui/button'
import { StyledLink } from 'baseui/link'
import { Notification, KIND as NOTIFICATION_KIND } from 'baseui/notification'

const Container = styled<{}>('div', ({ $theme }) => ({
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  backgroundColor: $theme.colors.backgroundSecondary,
}))

export default () => {
  const history = useHistory()

  function handleClickLogout() {
    doLogout()
    history.push('login')
  }

  return (
    <Container>
      <Notification kind={NOTIFICATION_KIND.negative}>
        Seems like you have no permissions to access the dashboard. Please
        contact us to get required permissions{' '}
        <StyledLink href="mailto:support@mozio.com">
          support@mozio.com
        </StyledLink>
      </Notification>
      <Button onClick={handleClickLogout}>Logout</Button>
    </Container>
  )
}
