// @flow
import * as React from 'react'
import { FormControl } from 'baseui/form-control'
import { Input, SIZE as INPUT_SIZE } from 'baseui/input'
import { COLUMNS } from './constants'
import type { ColumnT } from './types'
import FilterShell from './filter-shell'

type OptionsT = {|
  maxWidth?: number,
  minWidth?: number,
  title: string,
|}

type FilterParametersT = {|
  description: string,
  query: string,
|}

type StringColumnT = ColumnT<FilterParametersT>

type StringFilterProps = {
  close: () => void,
  title: string,
  setFilter: (FilterParametersT) => void,
  filterParams?: FilterParametersT,
}

export function StringFilter(props: StringFilterProps) {
  const [query, setQuery] = React.useState(props.filterParams?.query || '')

  return (
    <FilterShell
      onApply={() => {
        const normQuery = (query || '').trim()
        if (normQuery.length > 0) {
          props.setFilter({
            description: normQuery,
            query: normQuery,
          })
        }
        props.close()
      }}
    >
      <FormControl label={props.title}>
        <Input
          size={INPUT_SIZE.compact}
          value={query}
          placeholder="Type filter query"
          onChange={(event) => setQuery(event.target.value)}
          clearable
        />
      </FormControl>
    </FilterShell>
  )
}

function StringColumn(options: OptionsT): StringColumnT {
  return {
    kind: COLUMNS.STRING,
    maxWidth: options.maxWidth,
    minWidth: options.minWidth,
    renderFilter: (props) => <StringFilter {...props} title={options.title} />,
    title: options.title,
  }
}

export default StringColumn
