// @flow
import * as React from 'react'
import { Card, StyledBody, StyledAction } from 'baseui/card'
import { Button, KIND, SIZE } from 'baseui/button'
import ChevronRight from 'baseui/icon/chevron-right'
import { withStyle } from 'baseui'
import { Label1, Display4, Caption1 } from 'baseui/typography'
import { Link } from 'react-router-dom'

const RestyledAction = withStyle(StyledAction, {
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '-10px',
  marginRight: '-10px',
})

export default ({
  className,
  styles,
  header,
  value,
  caption,
  seeAllPath,
}: {
  className?: string,
  styles?: Object,
  header: React.Node,
  value: React.Node,
  caption?: React.Node,
  seeAllPath?: string,
}) => {
  return (
    <Card className={className} styles={styles}>
      <StyledBody>
        <Label1>{header}</Label1>
        <Display4>{value}</Display4>
        {!!caption && <Caption1>{caption}</Caption1>}
      </StyledBody>
      {!!seeAllPath && (
        <RestyledAction>
          <Button
            $as={Link}
            to={seeAllPath}
            size={SIZE.compact}
            kind={KIND.minimal}
            endEnhancer={() => <ChevronRight size={30} />}
            overrides={{
              BaseButton: {
                style: {
                  paddingRight: 0,
                },
              },
              EndEnhancer: {
                style: {
                  marginLeft: 0,
                },
              },
            }}
          >
            See all
          </Button>
        </RestyledAction>
      )}
    </Card>
  )
}
