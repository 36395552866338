// @flow
import * as React from 'react'
import Layout from '../../components/Layout'
import PartnersSummary from '../../components/PartnersSummary'
import ReservationsList from '../../components/ReservationsList'

export default () => (
  <Layout>
    <PartnersSummary />
    <ReservationsList />
  </Layout>
)
