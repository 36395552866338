// @flow

import React from 'react'
import { Button, SHAPE, SIZE } from 'baseui/button'
import { Filter as FilterIcon } from 'baseui/icon'
import { Input, SIZE as INPUT_SIZE } from 'baseui/input'
import { Popover, PLACEMENT } from 'baseui/popover'
import { useStyletron } from 'baseui'
import getBuiId from 'baseui/utils/get-bui-id'

import { COLUMNS } from './constants'
import { matchesQuery } from './text-search'
import type { ColumnT } from './types'

import { isFocusVisible } from 'baseui/utils/focusVisible'

function ColumnIcon(props: { column: ColumnT<> }) {
  if (props.column.kind === COLUMNS.STRING) {
    return 'abc'
  }
  if (props.column.kind === COLUMNS.DATETIME) {
    return 'dt'
  }
  return <FilterIcon />
}

type OptionsPropsT = {
  columns: ColumnT<>[],
  highlightIndex: number,
  onClick: (ColumnT<>) => void,
  onKeyDown: (KeyboardEvent) => void,
  onMouseEnter: (number) => void,
  onQueryChange: (string) => void,
  query: string,
  searchable: boolean,
}

function Options(props: OptionsPropsT) {
  const [css, theme] = useStyletron()
  const inputRef = React.useRef(null)
  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef.current])

  const [focusVisible, setFocusVisible] = React.useState(false)
  const buiRef = React.useRef(props.columns.map(() => getBuiId()))

  const handleFocus = (event: SyntheticEvent<>) => {
    if (isFocusVisible(event)) {
      setFocusVisible(true)
    }
  }

  const handleBlur = () => {
    if (focusVisible !== false) {
      setFocusVisible(false)
    }
  }

  return (
    <div
      className={css({
        backgroundColor: theme.colors.backgroundPrimary,
        minWidth: '320px',
        outline: focusVisible ? `3px solid ${theme.colors.accent}` : 'none',
        paddingTop: theme.sizing.scale600,
        paddingBottom: theme.sizing.scale600,
      })}
    >
      <p
        className={css({
          ...theme.typography.font100,
          marginTop: 'unset',
          paddingRight: theme.sizing.scale600,
          paddingLeft: theme.sizing.scale600,
        })}
      >
        Select column to filter by
      </p>

      {props.searchable && (
        <div
          className={css({
            marginBottom: theme.sizing.scale500,
            marginRight: theme.sizing.scale600,
            marginLeft: theme.sizing.scale600,
          })}
        >
          <Input
            inputRef={inputRef}
            value={props.query}
            onChange={(event) => props.onQueryChange(event.target.value)}
            placeholder={'Search for a column'}
            size={INPUT_SIZE.compact}
            clearable
          />
        </div>
      )}

      {!props.columns.length && (
        <div
          className={css({
            ...theme.typography.font100,
            paddingRight: theme.sizing.scale600,
            paddingLeft: theme.sizing.scale600,
          })}
        >
          No columns available
        </div>
      )}

      <ul
        onKeyDown={props.onKeyDown}
        onFocus={handleFocus}
        onBlur={handleBlur}
        tabIndex="0"
        role="listbox"
        aria-activedescendant={`bui-${buiRef.current[props.highlightIndex]}`}
        className={css({
          listStyleType: 'none',
          marginBlockStart: 'unset',
          marginBlockEnd: 'unset',
          paddingInlineStart: 'unset',
          outline: 'none',
        })}
      >
        {props.columns.map((column, index) => {
          const isHighlighted = index === props.highlightIndex

          return (
            // handled on the wrapper element
            <li
              id={`bui-${buiRef.current[index]}`}
              role="option"
              aria-selected={isHighlighted}
              onMouseEnter={() => props.onMouseEnter(index)}
              onClick={() => props.onClick(column)}
              key={column.title}
              className={css({
                ...theme.typography.font100,
                alignItems: 'center',
                backgroundColor: isHighlighted
                  ? theme.colors.backgroundSecondary
                  : null,
                cursor: 'pointer',
                display: 'flex',
                paddingTop: theme.sizing.scale100,
                paddingRight: theme.sizing.scale600,
                paddingBottom: theme.sizing.scale100,
                paddingLeft: theme.sizing.scale600,
              })}
            >
              <div
                className={css({
                  ...theme.typography.font150,
                  fontSize: '8px',
                  alignItems: 'center',
                  backgroundColor: theme.colors.backgroundTertiary,
                  borderRadius: theme.borders.radius200,
                  display: 'flex',
                  height: theme.sizing.scale800,
                  justifyContent: 'center',
                  marginRight: theme.sizing.scale300,
                  width: theme.sizing.scale800,
                })}
              >
                <ColumnIcon column={column} />
              </div>
              {column.title}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

type PropsT = {
  isLoading?: boolean,
  columns: ColumnT<>[],
  // eslint-disable-next-line flowtype/no-weak-types
  filters: Map<string, any>,
  // eslint-disable-next-line flowtype/no-weak-types
  onSetFilter: (
    columnTitle: string,
    filterParams: { description: string }
  ) => void,
}

function FilterMenu(props: PropsT) {
  const [, theme] = useStyletron()
  const [isOpen, setIsOpen] = React.useState(false)
  const [highlightIndex, setHighlightIndex] = React.useState(-1)
  const [query, setQuery] = React.useState('')

  const [activeColumn, setActiveColumn] = React.useState(null)
  const handleOptionClick = React.useCallback(setActiveColumn, [])
  const handleClose = React.useCallback(() => {
    setIsOpen(false)
    setActiveColumn(null)
    setHighlightIndex(-1)
    setQuery('')
  }, [])

  const filterableColumns = React.useMemo(() => {
    return props.columns.filter((column) => {
      return !props.filters.has(column.title)
    })
  }, [props.columns, props.filters])

  const columns = React.useMemo(() => {
    return filterableColumns.filter((column) =>
      matchesQuery(column.title, query)
    )
  }, [filterableColumns, query])

  const Filter = React.useMemo(() => {
    if (!activeColumn) return null
    return activeColumn.renderFilter
  }, [activeColumn])

  function handleKeyDown(event) {
    if (event.keyCode === 13) {
      event.preventDefault()
      setActiveColumn(columns[highlightIndex])
    }
    if (event.keyCode === 38) {
      event.preventDefault()
      setHighlightIndex(Math.max(0, highlightIndex - 1))
    }
    if (event.keyCode === 40) {
      event.preventDefault()
      if (!isOpen) {
        setIsOpen(true)
      } else {
        setHighlightIndex(Math.min(columns.length - 1, highlightIndex + 1))
      }
    }
  }

  return (
    <Popover
      focusLock
      returnFocus={true}
      placement={PLACEMENT.bottomRight}
      content={() => {
        if (Filter && activeColumn) {
          return (
            <Filter
              close={handleClose}
              setFilter={(filterParams) =>
                props.onSetFilter(activeColumn.title, filterParams)
              }
            />
          )
        }
        return (
          <Options
            columns={columns}
            highlightIndex={highlightIndex}
            onClick={handleOptionClick}
            onKeyDown={handleKeyDown}
            onMouseEnter={setHighlightIndex}
            onQueryChange={setQuery}
            query={query}
            searchable={filterableColumns.length >= 10}
          />
        )
      }}
      onClick={() => {
        if (isOpen) {
          handleClose()
        } else {
          setIsOpen(true)
        }
      }}
      onClickOutside={handleClose}
      onEsc={handleClose}
      isOpen={isOpen}
      ignoreBoundary
    >
      <Button
        isLoading={props.isLoading}
        shape={SHAPE.pill}
        size={SIZE.compact}
        overrides={{
          BaseButton: {
            style: {
              marginTop: theme.sizing.scale400,
              marginRight: theme.sizing.scale400,
              marginBottom: theme.sizing.scale400,
            },
          },
        }}
      >
        Add Filter
      </Button>
    </Popover>
  )
}

export default FilterMenu
