// @flow
import * as React from 'react'
import Layout from '../../components/Layout'
import PaymentsList from '../../components/PaymentsList'
import ArrowLeft from 'baseui/icon/arrow-left'
import { H3 } from 'baseui/typography'
import { Button } from 'baseui/button'
import { Block } from 'baseui/block'
import { Link } from 'react-router-dom'

export default () => (
  <Layout>
    <Block>
      <Button
        $as={Link}
        to="/dashboard"
        startEnhancer={() => <ArrowLeft size={24} />}
      >
        Dashboard
      </Button>
    </Block>
    <H3>Payments</H3>
    <PaymentsList />
  </Layout>
)
