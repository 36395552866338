import React from 'react'
import isLoggedIn from 'mz-sdk/services/user/isLoggedIn'
import { Client as Styletron } from 'styletron-engine-atomic'
import { Provider as StyletronProvider } from 'styletron-react'
import { BaseProvider } from 'baseui'
import MozioTheme from './theme'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import Payments from './pages/Payments'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import NoPermissions from './pages/NoPermissions'

const engine = new Styletron()

function PageRedirect() {
  if (isLoggedIn()) {
    return <Redirect to="dashboard" />
  }
  return <Redirect to="login" />
}

export default function Hello() {
  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={MozioTheme}>
        <Router>
          <Switch>
            <Route path="/payments">
              <Payments />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/dashboard">
              <Dashboard />
            </Route>
            <Route path="/request-permissions">
              <NoPermissions />
            </Route>
            <Route path="/">
              <PageRedirect />
            </Route>
          </Switch>
        </Router>
      </BaseProvider>
    </StyletronProvider>
  )
}
