// @flow
import { useState, useCallback } from 'react'
import { createKeyValueStore } from 'mz-ui-kit/logic/KeyValueStore'

const { useKeyValue } = createKeyValueStore()

export default function useCachedState<S>(
  key: string,
  initValue: (() => S) | S
): [S, (((S) => S) | S) => void] {
  const [cachedValue, cachedSet] = useKeyValue(key)
  const [value, set] = useState<S>(
    cachedValue === undefined ? initValue : cachedValue
  )

  const setWithCache = useCallback((newValue: *) => {
    cachedSet(newValue)
    set(newValue)
  }, [])

  return [value, setWithCache]
}
